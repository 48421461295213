







import { Vue, Component } from 'vue-property-decorator'
@Component
export default class NotFound extends Vue {
  private handleBack() {
    this.$router.go(-1)
  }
}
